angular.module('aerosApp')
    .constant("OTDRDemo", {
            imageMap: {
                backgroundStatus: {
                    "PassedOrComplete": "/static/images/event_assets/BD_Pass_Icn-.svg",
                    "Failed": "/static/images/event_assets/BD_Fail_Icn-.svg",
                    "NotEvaluated": "/static/images/event_assets/BD_NotEvaluated_Icn-.svg",
                    "NotApplicable": "/static/images/event_assets/BD_NotEvaluated_Icn-.svg"
                },
                statusIcon: {
                    "PassedOrComplete": "/static/images/event_assets/Check_Pass_Round_Icn-.svg",
                    "Failed": "/static/images/event_assets/Check_Fail_Round_Icn-.svg"
                },
                eventIcon: {
                    "Connector": "/static/images/event_assets/ES_Connector_Icn-.svg",
                    "Macrobend": "/static/images/event_assets/ES_Microband_Icn-.svg",
                    "OpenEnd": "/static/images/event_assets/ES_OpenEnd_Icn-.svg",
                    "Splice": "/static/images/event_assets/ES_Splice_Icn-.svg",
                    "Splitter": "/static/images/event_assets/ES_Splitter_Icn-.svg" // ? haven't seen this represented yet
                },
                specialIcon: {
                    "Start": "/static/images/event_assets/ET_Start_Icn-.svg",
                    "End": "/static/images/event_assets/ET_End_Icn-.svg",
                    "Macrobend": "/static/images/event_assets/ET_Marcoband_Icn-.svg",
                    "Splitter": "/static/images/event_assets/ET_Splitter_Icn-.svg"
                },
                reflectionType: {
                    "Reflective": "/static/images/event_assets/ET_Reflective_Icn-.svg",
                    "NonReflectiveLoss": "/static/images/event_assets/ET_Non_ReflectiveLoss_Icn-.svg",
                    "NonReflectiveGain": "/static/images/event_assets/ET_Non_ReflectiveGain_Icn-.svg"
                },
                fiberStatus: {
                    "PassedOrComplete": "/static/images/event_assets/Fiber_Seg_Pass_Icn-.svg",
                    "Failed": "/static/images/event_assets/Fiber_Seg_Fail_Icn-.svg",
                    "NotEvaluated": "/static/images/event_assets/Fiber_Segment_Icn-.svg",
                    "NotApplicable": "/static/images/event_assets/Fiber_Segment_Icn-.svg",
                    "LaunchCable": "/static/images/event_assets/Fiber_Seg_NE_Icn-.svg",	// ? "NE" = "not evaluated"? but only seems to be used for lauch & receive cables
                    "ReceiveCable": "/static/images/event_assets/Fiber_Seg_NE_Icn-.svg"
                },
                OTDR: {
                    "LaunchCable": "/static/images/event_assets/OTDR_NotEvaluated_Icn-.svg",
                    "NoLaunchCable": "/static/images/event_assets/OTDR_Icn-.svg"
                },
                end: {
                    "ReceiveCable": "/static/images/event_assets/ReceiveCable_NotEvaluated_Icn-.svg",
                    "NoReceiveCable": "/static/images/event_assets/ReceiveCable_Icn-.svg",
                    "end": "/static/images/event_assets/end.png"
                }
            }
        }
    );